<template>
  <div id="app">
    <Home
      :api_key="$root.api_key"
      :shop_id="$root.shop_id"
      :currency="$root.currency"
      :amount="$root.amount"
      :text_btn="$root.text_btn"
      :order_id="$root.order_id"
      :background="$root.background"
      :color="$root.color"
      :border_color="$root.border_color"
      :logo="$root.logo"
      :width="$root.width"
      :email="$root.email"
    ></Home>
  </div>
</template>
<script>
import Home from "./views/PayButton.vue";
export default {
  props: [
    "api_key",
    "shop_id",
    "currency",
    "amount",
    "text_btn",
    "order_id",
    "background",
    "color",
    "border_color",
    "logo",
    "width",
    "email",
  ],
  components: { Home },
};
</script>
