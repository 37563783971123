import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueCustomElement from 'vue-custom-element'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/css/app.css'

Vue.use(vueCustomElement);
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
App.store = store
App.router = router
Vue.customElement('vue-widget', App)