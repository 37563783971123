<template>
  <a
    @click.prevent="createinvoice"
    class="btn-crypto mx-auto"
    :style="styleObject"
  >
    <span class="centred">
      <img
        src="https://api.cryptocloud.plus/static/widget/img/1.svg"
        width="40px"
        style="margin-right: 20px"
        v-if="logo == 'color'"
      />
      <img
        src="https://api.cryptocloud.plus/static/widget/img/2.svg"
        width="40px"
        style="margin-right: 20px"
        v-else-if="logo == 'white'"
      />

      <img
        src="https://api.cryptocloud.plus/static/widget/img/1.svg"
        width="40px"
        style="margin-right: 20px"
        v-else
      />
      {{ text_btn }}</span
    >
  </a>
</template>

<script>
import axios from "axios";
export default {
  name: "PayButton",
  props: [
    "api_key",
    "shop_id",
    "currency",
    "amount",
    "text_btn",
    "order_id",
    "email",
    "background",
    "color",
    "border_color",
    "logo",
    "width",
  ],
  data() {
    return {
      invoice_created: false,
      pay_url: null,
      error: null,
      text_btn: "Оплатить",
      logo: "color",
      styleObject: {
        background: this.background,
        color: this.color,
        border: "1px solid " + this.border_color,
        width: this.width,
      },
    };
  },
  methods: {
    createinvoice() {
      var currency = this.currency != undefined ? this.currency : "RUB";
      var currency = this.currency != undefined ? this.currency : "RUB";
      axios.defaults.headers.common["Authorization"] = "Token " + this.api_key;
      var url = "https://api.cryptocloud.plus/v1/invoice/create";
      var data = {
        shop_id: this.shop_id,
        amount: this.amount,
        order_id: this.order_id,
        email: this.email,
        currency: currency,
      };
      axios.post(url, data).then((response) => {
        if (response.data.status == "success") {
          try {
            window.open(response.data.pay_url, "_blank").focus();
          } catch {
            window.location.replace(response.data.pay_url);
            console.log("error open new tab");
          }
          this.pay_url = response.data.pay_url;
          this.invoice_created = false;
          this.amount = this.email = null;
        }
      });
    },
  },
  mounted() {
    console.log(this.styleObject);
  },
};
</script>

<style scoped></style>
